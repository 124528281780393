import { Settings } from 'book-cover-3d'
import React from 'react'
import { RangeInput } from './range-input'

export interface Props {
  settings: Settings
  onSettingsChanged: (settings: Settings) => void
}

export const Controls = ({ settings, onSettingsChanged }: Props) => {
  return (
    <div className="flex flex-col space-y-1 w-full max-w-md">
      <RangeInput
        label="Rotate"
        unit="deg"
        min={0}
        max={45}
        value={settings.rotate}
        onChange={(e) =>
          onSettingsChanged({ ...settings, rotate: Number(e.target.value) })
        }
      />
      <RangeInput
        label="Rotate (hover)"
        unit="deg"
        min={0}
        max={45}
        value={settings.rotateHover}
        onChange={(e) =>
          onSettingsChanged({
            ...settings,
            rotateHover: Number(e.target.value),
          })
        }
      />
      <RangeInput
        label="Perspective"
        unit="px"
        min={300}
        max={1300}
        value={settings.perspective}
        onChange={(e) =>
          onSettingsChanged({
            ...settings,
            perspective: Number(e.target.value),
          })
        }
      />
      <RangeInput
        label="Anim. duration"
        unit="s"
        min={0}
        max={5}
        value={settings.transitionDuration}
        onChange={(e) =>
          onSettingsChanged({
            ...settings,
            transitionDuration: Number(e.target.value),
          })
        }
      />
      <RangeInput
        label="Radius"
        unit="px"
        min={0}
        max={5}
        value={settings.radius}
        onChange={(e) =>
          onSettingsChanged({ ...settings, radius: Number(e.target.value) })
        }
      />
      <RangeInput
        label="Thickness"
        unit="px"
        min={5}
        max={100}
        value={settings.thickness}
        onChange={(e) =>
          onSettingsChanged({ ...settings, thickness: Number(e.target.value) })
        }
      />
      <RangeInput
        label="Width"
        unit="px"
        min={50}
        max={300}
        value={settings.width}
        onChange={(e) =>
          onSettingsChanged({ ...settings, width: Number(e.target.value) })
        }
      />
      <RangeInput
        label="Height"
        unit="px"
        min={75}
        max={450}
        value={settings.height}
        onChange={(e) =>
          onSettingsChanged({ ...settings, height: Number(e.target.value) })
        }
      />
      <RangeInput
        label="Pages offset"
        unit="px"
        min={0}
        max={10}
        value={settings.pagesOffset}
        onChange={(e) =>
          onSettingsChanged({
            ...settings,
            pagesOffset: Number(e.target.value),
          })
        }
      />
      <label className="flex flex-col">
        <span className="w-32 text-sm">Background color</span>
        <div className="flex space-x-2 items-center">
          <span className="flex-auto flex space-x-1 items-stretch self-stretch">
            <input
              className="w-24"
              type="text"
              value={settings.bgColor || ''}
              onChange={(e) =>
                onSettingsChanged({ ...settings, bgColor: e.target.value })
              }
            />
            <input
              type="color"
              value={settings.bgColor || ''}
              className="h-full"
              onChange={(e) =>
                onSettingsChanged({ ...settings, bgColor: e.target.value })
              }
            />
          </span>
        </div>
      </label>
      <label className="flex flex-col">
        <span className="w-32 text-sm">Shadow color</span>
        <div className="flex space-x-2 items-center">
          <span className="flex-auto flex space-x-1 items-stretch self-stretch">
            <input
              className="w-24"
              type="text"
              value={settings.shadowColor || ''}
              onChange={(e) =>
                onSettingsChanged({ ...settings, shadowColor: e.target.value })
              }
            />
            <input
              type="color"
              value={settings.shadowColor || ''}
              className="h-full"
              onChange={(e) =>
                onSettingsChanged({ ...settings, shadowColor: e.target.value })
              }
            />
          </span>
        </div>
      </label>
    </div>
  )
}
