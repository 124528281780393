import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useMemo, useState } from 'react'
import { Account, Book } from './types'

export const fetchJson = async <T>(
  ...args: Parameters<typeof fetch>
): Promise<T> => {
  const res = await fetch(...args)
  const json = await res.json()
  if (json.error)
    throw new Error(`${json.error} (${json.statusCode}): ${json.message}`)
  return json
}

export const useAccessToken = () => {
  const [token, setToken] = useState<string | undefined>()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!token && isAuthenticated) {
      getAccessTokenSilently().then(setToken)
    }
  }, [token, isAuthenticated])

  return token
}

export const useApi = () => {
  const token = useAccessToken()
  const headers = { Authorization: `Bearer ${token}` }

  const api = useMemo(
    () =>
      token
        ? {
            async getBooks(): Promise<Book[]> {
              return fetchJson('/api/books', { headers })
            },
            async getBook(bookId: string): Promise<Book | null> {
              return fetchJson(`/api/books/${bookId}`, { headers })
            },
            async updateBook(
              bookId: string,
              book: Omit<Book, '_id' | 'coverUrl'>,
              coverFile: File | undefined
            ): Promise<Book> {
              const formData = new FormData()
              formData.append('title', book.title)
              formData.append('href', book.href)
              formData.append('settings', JSON.stringify(book.settings))
              if (coverFile) formData.append('coverFile', coverFile)
              return fetchJson(`/api/books/${bookId}`, {
                method: 'PUT',
                body: formData,
                headers,
              })
            },
            async deleteBook(bookId: string): Promise<void> {
              await fetchJson(`/api/books/${bookId}`, {
                method: 'DELETE',
                headers,
              })
            },
            async createBook(): Promise<Book> {
              return fetchJson('/api/books', {
                method: 'POST',
                headers,
              })
            },
            async getAccount(): Promise<Account> {
              return fetchJson('/api/account', {
                method: 'GET',
                headers,
              })
            },
            async resetPassword(): Promise<Account> {
              return fetchJson('/api/account/reset-password', {
                method: 'POST',
                headers,
              })
            },
            async createPortalSession(): Promise<string> {
              const { url } = await fetchJson(
                '/api/account/create-portal-session',
                {
                  method: 'POST',
                  headers,
                }
              )
              return url
            },
            async createCheckoutSession(
              priceId: string
            ): Promise<{ sessionId: string }> {
              const { sessionId } = await fetchJson(
                '/api/account/create-checkout-session',
                {
                  method: 'POST',
                  headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ priceId }),
                }
              )
              return sessionId
            },
          }
        : undefined,
    [token]
  )

  return api
}

export const useAccount = () => {
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [account, setAccount] = useState<Account | undefined>(undefined)

  useEffect(() => {
    if (api) {
      setLoading(true)
      api
        .getAccount()
        .then((account) => {
          setAccount(account)
          setLoading(false)
        })
        .catch(() => setError(true))
    }
  }, [api])

  return { account, loading, error }
}
