import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useApi } from '../hooks'
import { Account } from '../types'

export const AccountPage = () => {
  const api = useApi()
  const [account, setAccount] = useState<Account | undefined>(undefined)
  const [error, setError] = useState(false)
  const [resetPasswordSent, setResetPasswordSent] = useState(false)
  const [sendingResetPassword, setSendingResetPassword] = useState(false)

  useEffect(() => {
    if (api) {
      api
        .getAccount()
        .then(setAccount)
        .catch(() => setError(true))
    }
  }, [api])

  const onResetPasswordClicked = useCallback(async () => {
    if (api) {
      setSendingResetPassword(true)
      await api.resetPassword()
      setResetPasswordSent(true)
      setSendingResetPassword(false)
    }
  }, [api])

  const onManageClicked = useCallback(async () => {
    if (api) {
      const url = await api.createPortalSession()
      document.location.href = url
    }
  }, [api])

  return (
    <div className="flex flex-col space-y-4">
      <h2>My account</h2>

      {error && <p className="text-red-600">An error occurred.</p>}

      {account && (
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 md:items-start">
          <div className="card flex flex-col space-y-4 items-start md:w-1/2">
            <h3>Subscription</h3>
            {account.subscription ? (
              <>
                <div className="flex flex-col space-y-1">
                  <div className="flex space-x-2 items-baseline">
                    <span className="w-28 text-sm text-gray-500">
                      Account type
                    </span>
                    <span>Premium</span>
                  </div>
                  <div className="flex space-x-2 items-baseline">
                    <span className="w-28 text-sm text-gray-500">
                      Next billing date
                    </span>
                    <span>
                      {new Date(account.subscription.endDate).toDateString()}
                    </span>
                  </div>
                </div>
                <p>
                  We partner with <strong>Stripe</strong> for simplified
                  billing. You can manage your subscription there: change
                  payment method, cancel your subscription, etc.
                </p>
                <button className="btn btn-primary" onClick={onManageClicked}>
                  Manage subscription
                </button>
              </>
            ) : (
              <>
                <div className="flex space-x-2 items-baseline">
                  <span className="w-28 text-sm text-gray-500">
                    Account type
                  </span>
                  <span>Free</span>
                </div>
                <Link to="/plans" className="btn btn-primary">
                  See plans
                </Link>
              </>
            )}
          </div>

          <div className="card flex flex-col space-y-4 items-start md:w-1/2">
            <h3>Change password</h3>
            <p>
              You will receive an e-mail with instructions to reset your
              password.
            </p>
            {resetPasswordSent ? (
              <p>An e-mail with instructions is on its way!</p>
            ) : (
              <button
                className="btn btn-primary"
                disabled={sendingResetPassword}
                onClick={onResetPasswordClicked}
              >
                {sendingResetPassword
                  ? 'Sending reset password e-mail…'
                  : 'Reset my password'}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
