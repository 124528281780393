import React from 'react'
import { Link } from 'react-router-dom'
import { Plans } from '../components/plans'
import { useAccount } from '../hooks'

export const PlansPage = () => {
  const { account } = useAccount()

  return (
    <div className="flex flex-col self-stretch">
      <div className="mb-8">
        <Link to="/account" className="text-blue-800 text-sm">
          ← Back to my account
        </Link>
      </div>

      <p className="text-center text-4xl font-bold">
        Get a more professional look for
        <br className="hidden sm:inline" /> your book cover, at a{' '}
        <strong className="text-blue-800 font-bold">fair price</strong>.
      </p>

      <p className="text-center text-2xl mt-6 text-gray-500 leading-8">
        Basic features are free and will stay free forever for your first book.
      </p>

      <Plans />
    </div>
  )
}
