import { useAuth0 } from '@auth0/auth0-react'
import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { FeedbackFish } from '@feedback-fish/react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import logo from 'url:../../public/logo.svg'
import '@szhsin/react-menu/dist/index.css'
import { useAccount } from '../hooks'

export const HeaderBar = () => {
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    user,
    isLoading,
  } = useAuth0()
  const history = useHistory()
  const { account } = useAccount()

  const menuItemsClass = useMemo(
    () => `flex active:bg-white hover:bg-blue-200`,
    []
  )

  return (
    <header className="flex justify-between items-center p-4 bg-white border-b border-gray-200">
      <div>
        <Link to="/" className="flex items-center space-x-2">
          <img src={logo} className="h-8" />
          <h1 className="text-blue-800 text-xl hidden sm:block">3D Book</h1>
        </Link>
      </div>
      <div>
        {!isLoading && (
          <ul className="flex text-sm sm:text-base space-x-2 sm:space-x-4 items-center">
            {isAuthenticated ? (
              <>
                <li className="hidden sm:block">{user.name}</li>
                <li>
                  <a className="text-blue-800 hover:underline" href="mailto:threedbook@proton.me">
                    Feedback?
                  </a>
                </li>
                <li>
                  <Link to="/books" className="text-blue-800 hover:underline">
                    Covers
                  </Link>
                </li>
                {!account?.subscription && (
                  <li>
                    <Link
                      to="/upgrade"
                      className="bg-yellow-700 text-sm text-white px-2 py-1 rounded-full hover:bg-yellow-600"
                    >
                      ★ Upgrade
                    </Link>
                  </li>
                )}
                <li>
                  <Menu
                    menuButton={
                      <button className="text-blue-800 hover:underline">
                        Account
                      </button>
                    }
                  >
                    <MenuItem
                      className={menuItemsClass}
                      onClick={() => history.push('/account')}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      className={menuItemsClass}
                      onClick={() => logout({ returnTo: process.env.BASE_URL })}
                    >
                      Sign out
                    </MenuItem>
                  </Menu>
                </li>
              </>
            ) : (
              <>
                <li>
                  <HashLink
                    className="text-blue-800 hover:underline"
                    to="/#pricing"
                  >
                    Pricing
                  </HashLink>
                </li>
                <li>
                  <button
                    className="text-blue-800 hover:underline"
                    onClick={() => loginWithRedirect()}
                  >
                    Sign in
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      loginWithRedirect({
                        screen_hint: 'signup',
                      })
                    }
                  >
                    Sign up
                  </button>
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    </header>
  )
}
