import React, { ComponentType, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { Footer } from './components/footer'
import { HeaderBar } from './components/header-bar'
import { IndexPage } from './pages'
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react'
import { BooksPage } from './pages/books'
import './styles.css'
import { BookPage } from './pages/book'
import { AccountPage } from './pages/account'
import { PlansPage } from './pages/plans'
import { UpgradePage } from './pages/upgrade'

const PrivateRoute = ({
  component,
  ...rest
}: Omit<RouteProps, 'component'> & { component: ComponentType<any> }) => {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <p>Loading…</p>,
        returnTo: window.location.pathname,
        loginOptions: { appState: { redirectTo: window.location.pathname } },
      })}
      {...rest}
    />
  )
}

export const App = () => {
  const history = useHistory()

  if (!process.env.AUTH0_DOMAIN || !process.env.AUTH0_CLIENT_ID)
    throw new Error('Missing Auth0 configuration.')

  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/books`}
      audience={process.env.AUTH0_AUDIENCE}
      scope="openid profile email"
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={(state) => {
        if (state?.redirectTo) {
          history.replace(state.redirectTo)
        } else {
          history.replace(history.location.pathname)
        }
      }}
    >
      <div className="flex flex-col min-h-screen">
        <div className="flex-none">
          <HeaderBar />
        </div>
        <main className="p-4 flex-auto w-full max-w-5xl mx-auto">
          <Switch>
            <Route path="/" exact component={IndexPage} />
            <PrivateRoute path="/books" exact component={BooksPage} />
            <PrivateRoute path="/books/:bookId" exact component={BookPage} />
            <PrivateRoute path="/account" exact component={AccountPage} />
            <PrivateRoute path="/plans" exact component={PlansPage} />
            <PrivateRoute path="/upgrade" exact component={UpgradePage} />
            <Route path="*">
              <p>Page not found.</p>
            </Route>
          </Switch>
        </main>
        <div className="flex-none">
          <Footer />
        </div>
      </div>
    </Auth0Provider>
  )
}
