import React from 'react'
import { BookCover, Settings } from 'book-cover-3d'

export const defaultSettings: Settings = {
  rotate: 30,
  rotateHover: 0,
  perspective: 600,
  transitionDuration: 1,
  radius: 2,
  thickness: 50,
  bgColor: '#01060f',
  shadowColor: '#aaaaaa',
  width: 200,
  height: 300,
  pagesOffset: 3,
}

export interface Props {
  imageUrl: string
  imageAlt?: string
  href?: string
  settings?: Settings
}

export const Book3D = ({
  imageUrl,
  imageAlt,
  href,
  settings = defaultSettings,
}: Props) => {
  const book = (
    <BookCover {...settings}>
      <img alt={imageAlt} src={imageUrl} />
    </BookCover>
  )

  return href ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {book}
    </a>
  ) : (
    book
  )
}
