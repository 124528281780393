import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Book3D, defaultSettings } from '../components/book-3d'
import { Controls } from '../components/controls'
import { getImageHeight, readDataUrlFromEvent } from '../helpers'
import { useAccount, useApi } from '../hooks'
import { Book } from '../types'

export const BookPage = () => {
  const { bookId } = useParams<{ bookId: string }>()
  const [book, setBook] = useState<Book | undefined>(undefined)
  const [error, setError] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [updating, setUpdating] = useState(false)
  const history = useHistory()
  const api = useApi()
  const [title, setTitle] = useState('')
  const [href, setHref] = useState('')
  const [coverUrl, setCoverUrl] = useState('')
  const [settings, setSettings] = useState(defaultSettings)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const coverInputRef = useRef<HTMLInputElement>(null)
  const [showSettings, setShowSettings] = useState(false)
  const { account } = useAccount()

  const refreshBook = async () => {
    try {
      const book = await api?.getBook(bookId)
      if (book) setBook(book)
      else history.replace('/books')
    } catch (err) {
      setError(true)
    }
  }

  useEffect(() => {
    if (api) refreshBook()
  }, [api])

  useEffect(() => {
    if (book) {
      setTitle(book.title || '')
      setHref(book.href || '')
      setSettings(book.settings || defaultSettings)
      setCoverUrl(book.coverUrl || '')
    }
  }, [book])

  const onDeleteClicked = useCallback(async () => {
    setDeleting(true)
    await api?.deleteBook(bookId)
    history.replace('/books')
  }, [api, bookId])

  const onFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setUpdating(true)
      const coverFile = coverInputRef.current?.files?.[0]
      await api?.updateBook(bookId, { title, href, settings }, coverFile)
      await refreshBook()
      setUpdating(false)
    },
    [api, book, title, href, settings]
  )

  const onCoverChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const dataUrl = await readDataUrlFromEvent(event)
      if (dataUrl) {
        const height = await getImageHeight(dataUrl, settings.width)
        setCoverUrl(dataUrl)
        setSettings({ ...settings, height })
      }
    },
    []
  )

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <Link to="/books" className="text-blue-800 text-sm">
          ← Back to book covers
        </Link>
      </div>

      {error && <p className="text-red-600">An error occurred.</p>}

      {book ? (
        <>
          <h2>{book.title}</h2>

          <form
            onSubmit={onFormSubmit}
            className="card flex flex-col space-y-16 sm:space-y-0 sm:flex-row"
          >
            <div className="flex-auto flex flex-col space-y-8 items-start">
              <h3>Edit book cover settings</h3>
              <div className="flex flex-col space-y-4 self-stretch">
                <label className="flex flex-col space-y-1 w-full max-w-xs">
                  <span className="text-sm">Book title</span>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    name="title"
                    className="w-full"
                  />
                  <small className="text-gray-500">
                    Used as “alt” for SEO and accessibility.
                  </small>
                </label>
                <label className="flex flex-col space-y-1 w-full max-w-xs">
                  <span className="text-sm">URL for link</span>
                  <input
                    value={href}
                    onChange={(e) => setHref(e.target.value)}
                    type="url"
                    name="href"
                    placeholder="https://"
                    className="w-full"
                  />
                  <small className="text-gray-500">
                    Leave empty if you don’t want a link.
                  </small>
                </label>
                <label className="flex flex-col space-y-1 items-start">
                  <span className="text-sm">Book cover image</span>
                  <div className="btn btn-secondary" role="button">
                    Choose…
                  </div>
                  <input
                    ref={coverInputRef}
                    type="file"
                    name="coverImage"
                    className="hidden"
                    accept="image/*"
                    onChange={onCoverChange}
                  />
                </label>
              </div>

              {account && !account.subscription && (
                <label className="flex space-x-2 items-center">
                  <input
                    className="switch"
                    type="checkbox"
                    defaultChecked={false}
                    disabled
                  />
                  <span className="text-gray-500 text-sm">
                    Remove watermark
                    <Link
                      to="/plans"
                      className="ml-2 text-xs bg-yellow-700 text-white px-2 py-1 rounded-full hover:bg-yellow-600"
                    >
                      ★ Premium feature
                    </Link>
                  </span>
                </label>
              )}

              <div>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => setShowSettings((show) => !show)}
                >
                  {showSettings ? 'Hide 3D settings' : 'Customize 3D settings…'}
                </button>
              </div>

              {showSettings && (
                <Controls settings={settings} onSettingsChanged={setSettings} />
              )}

              <footer className="flex-auto flex items-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={updating}
                >
                  {updating ? 'Saving…' : 'Save'}
                </button>
              </footer>
            </div>

            <div className="flex-none md:flex-auto">
              <div className="mx-8 md:mx-16 my-8">
                {coverUrl && (
                  <Book3D
                    imageUrl={coverUrl}
                    imageAlt={title}
                    href={href}
                    settings={settings}
                  />
                )}
              </div>
            </div>
          </form>

          <div className="card flex flex-col space-y-4">
            <h3>Embed your book cover</h3>
            <p>
              Copy and paste the HTML code below in the web page where you want
              to embed your 3D book cover.
            </p>
            <p className="bg-blue-100 font-mono text-sm px-4 py-2 rounded whitespace-nowrap overflow-x-scroll">{`<script src="${process.env.BASE_URL}/api/books/${book._id}/cover.js"></script>`}</p>
          </div>

          <div className="card flex flex-col space-y-4 items-start">
            <h3>Danger zone</h3>
            {confirmingDelete ? (
              <div className="flex space-x-2 items-center">
                <span>This action can not be reverted. Are you sure?</span>
                <button
                  onClick={onDeleteClicked}
                  disabled={deleting}
                  className="btn btn-primary"
                >
                  {deleting ? 'Deleting…' : 'Yes, delete'}
                </button>
                {!deleting && (
                  <button
                    className="btn btn-link"
                    onClick={() => setConfirmingDelete(false)}
                  >
                    Cancel
                  </button>
                )}
              </div>
            ) : (
              <button
                onClick={() => setConfirmingDelete(true)}
                className="btn btn-primary"
              >
                Delete book
              </button>
            )}
          </div>
        </>
      ) : null}
    </div>
  )
}
