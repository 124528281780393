import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer className="mt-16 bg-blue-900 text-blue-200 md:p-16 sm:p-8 p-4 leading-7">
      <p className="text-2xl mb-4">
        <Link to="/" className="text-white">
          3D Book
        </Link>
      </p>
      <p>Inspired by <a className="text-white" href="https://www.creativindie.com" target="_blank" rel="noopener noreferrer">Creativindie</a></p>
      <p>Need help with your book launch?</p>
      <p><a className="text-white" href="https://creativindie.clickfunnels.com/optin-24185691" target="_blank" rel="noopener noreferrer">Grab our free guide to book marketing.</a></p>
    </footer>
  )
}
