import { useAuth0 } from "@auth0/auth0-react";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Book3D, defaultSettings } from "../components/book-3d";
import demoCover from "url:../../public/demo-book.png";
import banner1link from "url:../../public/1link_banner.png";
import screenshot from "url:../../public/editor-screenshot.png";
import { getImageHeight, readDataUrlFromEvent } from "../helpers";
import AOS from "aos";
import "aos/dist/aos.css";
import { fetchJson, useAccount, useApi } from "../hooks";
import { Plans } from "../components/plans";
import { Book } from "../types";

export const IndexPage = () => {
  const { loginWithRedirect } = useAuth0();
  const [tempBook, setTempBook] = useState<Book>({
    _id: "",
    coverUrl: demoCover,
    title: "Untitled book",
    href: "",
    settings: defaultSettings,
  });
  const [uploadingCover, setUploadingCover] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const onCoverChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const coverFile = event.target.files?.[0];
      if (coverFile) {
        setUploadingCover(true);

        const formData = new FormData();
        formData.append("title", "Untitled book");
        formData.append("href", "");
        formData.append("settings", JSON.stringify(defaultSettings));
        formData.append("coverFile", coverFile);
        const book = await fetchJson<Book>(`/api/create-temp-book`, {
          method: "POST",
          body: formData,
        });

        setUploadingCover(false);
        setTempBook(book);
      }
    },
    []
  );

  return (
    <div className="flex flex-col items-center mb-16">
      <p className="text-center text-3xl sm:text-4xl mt-16 font-bold">
        Give some perspective to your{" "}
        <strong className="text-blue-800 font-bold">book cover</strong>.
      </p>
      <p className="text-center text-lg sm:text-2xl mt-6 text-gray-500 leading-8">
        Upload your cover, customize the settings you want, and{" "}
        <br className="hidden sm:inline" />
        embed the resulting 3D animated cover on your website.
      </p>
      <div className="text-center mt-8">
        <button
          className="btn btn-primary btn-big"
          onClick={() => loginWithRedirect({ screen_hint: "signup" })}
        >
          Sign up for free
        </button>
      </div>

      <div data-aos="fade" className="flex flex-col self-stretch">
        <p className="mt-16 text-center text-3xl font-bold">
          Want to give it a try? 👇🏻
        </p>

        <div className="mt-16 flex-auto flex-col md:flex-row p-4 rounded-lg self-center w-full max-w-3xl flex space-y-4 sm:space-x-2 items-center">
          <div className="bg-white shadow-xl rounded-xl flex flex-col space-y-4 items-center flex-auto p-8">
            {tempBook._id !== "" ? (
              <>
                <p className="text-xl text-center">
                  Like it? Additional settings are
                  <br className="hidden sm:inline" />
                  available with a{" "}
                  <strong className=" text-blue-800">free</strong> account.
                </p>
                <p className="text-center">
                  Create yours to embed this <br className="hidden sm:inline" />{" "}
                  cover on your website.
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    loginWithRedirect({
                      screen_hint: "signup",
                      appState: {
                        redirectTo:
                          tempBook._id !== ""
                            ? `/books/${tempBook._id}`
                            : undefined,
                      },
                    })
                  }
                >
                  Create my account
                </button>
              </>
            ) : (
              <p className="text-xl text-center">
                Select your own cover to see <br className="hidden sm:inline" />{" "}
                what it looks like in 3D.
              </p>
            )}
            <label
              className={
                (tempBook._id !== "" ? "btn btn-link" : "btn btn-secondary") +
                (uploadingCover ? " disabled" : "")
              }
              role="button"
            >
              {uploadingCover
                ? "Uploading cover…"
                : tempBook._id !== ""
                ? "Choose another cover…"
                : "Choose my cover…"}
              <input
                type="file"
                accept="image/*"
                onChange={onCoverChange}
                className="hidden"
              />
            </label>
          </div>
          <div className="p-8 flex-auto">
            <Book3D imageUrl={tempBook.coverUrl} settings={tempBook.settings} />
          </div>
        </div>
      </div>

      <div
        data-aos="fade"
        className="flex flex-col items-center space-y-4 bg-blue-100 p-4 rounded-xl shadow-xl"
      >
        <p className="text-center text-lg text-gray-800 leading-8">
          👋 Would you like to integrate a 3D cover of your book on our new
          sibling project{" "}
          <a
            href="https://1link.st?ref=3dbook"
            target="_blank"
            rel="noopener"
            className="text-blue-800"
          >
            1link
          </a>
          ? It’s free!
        </p>
        <a href="https://1link.st?ref=3dbook" target="_blank" rel="noopener">
          <img
            src={banner1link}
            alt=""
            className="w-full max-w-lg border rounded"
          />
        </a>
      </div>

      <div data-aos="fade" className="flex flex-col self-stretch">
        <p className="text-center text-4xl mt-32 font-bold">
          Just a few steps to get your{" "}
          <strong className="text-blue-800 font-bold">3D cover</strong>.
        </p>

        <div className="mt-16 flex flex-col lg:flex-row items-center space-y-8">
          <div className="flex-auto lg:w-1/2">
            <ul className="flex flex-col space-y-8">
              <li className="flex space-x-4">
                <span className="flex-none mt-1 text-lg bg-blue-800 text-white rounded-full h-8 w-8 flex items-center justify-center">
                  1
                </span>
                <div className="flex flex-col">
                  <span className="text-xl">Create your account for free</span>
                  <span className="text-gray-500">
                    Access all features for your first book.
                  </span>
                </div>
              </li>
              <li className="flex space-x-4">
                <span className="flex-none mt-1 text-lg bg-blue-800 text-white rounded-full h-8 w-8 flex items-center justify-center">
                  2
                </span>
                <div className="flex flex-col">
                  <span className="text-xl">Upload your book cover image</span>
                  <span className="text-gray-500">Choose any image file.</span>
                </div>
              </li>
              <li className="flex space-x-4">
                <span className="flex-none mt-1 text-lg bg-blue-800 text-white rounded-full h-8 w-8 flex items-center justify-center">
                  3
                </span>
                <div className="flex flex-col">
                  <span className="text-xl">Tweak 3D settings</span>
                  <span className="text-gray-500">
                    Rotation, perspective, book thickness, etc.
                  </span>
                </div>
              </li>
              <li className="flex space-x-4">
                <span className="flex-none mt-1 text-lg bg-blue-800 text-white rounded-full h-8 w-8 flex items-center justify-center">
                  4
                </span>
                <div className="flex flex-col">
                  <span className="text-xl">
                    Embed the 3D cover on your website
                  </span>
                  <span className="text-gray-500">
                    Copy-paste the generated code snippet on your personal
                    website or blog.
                  </span>
                </div>
              </li>
            </ul>
            <div className="flex justify-center mt-8 lg:mt-12">
              <button
                className="btn btn-primary btn-big"
                onClick={() => loginWithRedirect({ screen_hint: "signup" })}
              >
                Sign up for free
              </button>
            </div>
          </div>
          <div className="flex-auto lg:w-2/3">
            <img src={screenshot} className="w-full" />
          </div>
        </div>
      </div>

      <div
        data-aos="fade"
        className="flex flex-col self-stretch mt-24 pt-8"
        id="pricing"
      >
        <p className="text-center text-4xl font-bold">
          Get a more professional look for
          <br className="hidden sm:inline" /> your book cover, at a{" "}
          <strong className="text-blue-800 font-bold">fair price</strong>.
        </p>

        <p className="text-center text-2xl mt-6 text-gray-500 leading-8">
          Basic features are free and will stay free forever for your first
          book.
        </p>

        <Plans />
      </div>

      <div data-aos="fade" className="flex flex-col self-stretch">
        <p className="text-center text-4xl mt-32 font-bold">
          Need 3D book mockups for{" "}
          <strong className="text-blue-800 font-bold">social media</strong>{" "}
          promo graphics?
        </p>

        <p className="text-center text-2xl mt-6 text-gray-500 leading-8">
          Make your book look{" "}
          <strong className="text-blue-800 font-bold">great</strong> and get a
          professional, branded author platform with our book marketing
          templates, premade covers, and 3D book mockups at{" "}
          <a
            className="text-blue-800 underline"
            href="https://diybookcovers.com/3Dmockups/"
            target="_blank"
            rel="noopener"
          >
            diybookcovers
          </a>
          .
        </p>
      </div>
    </div>
  );
};
