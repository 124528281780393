import { ChangeEvent } from 'react'

export const getImageHeight = (dataUrl: string, width: number) => {
  return new Promise<number>((resolve) => {
    const img = document.createElement('img')
    img.src = dataUrl
    img.width = width
    img.setAttribute('class', 'invisible')
    document.body.appendChild(img)
    img.addEventListener('load', () => {
      const height = img.offsetHeight
      document.body.removeChild(img)
      resolve(height)
    })
  })
}

export const readDataUrlFromEvent = (event: ChangeEvent<HTMLInputElement>) => {
  return new Promise<string | undefined>((resolve) => {
    const input = event.target
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = (e) => resolve(e.target!.result as string)
      reader.readAsDataURL(input.files[0])
    } else {
      resolve(undefined)
    }
  })
}
