import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useApi } from '../hooks'

const stripe = (window as any).Stripe(process.env.STRIPE_PUBLIC_KEY)

export const UpgradePage = () => {
  const api = useApi()

  const checkOut = async () => {
    const sessionId = await api!.createCheckoutSession(
      process.env.PREMIUM_PRICE_ID!
    )
    stripe.redirectToCheckout({ sessionId })
  }

  const onUpgradeClicked = useCallback(async () => {
    if (api) {
      checkOut()
    }
  }, [api])

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <Link to="/plans" className="text-blue-800 text-sm">
          ← Back to plans
        </Link>
      </div>
      <h2>Upgrade to Premium</h2>
      <div className="card flex flex-col space-y-4 items-start">
        <h3>Confirm subscription</h3>
        <table>
          <tbody>
            <tr>
              <td className="pr-8">Monthly subscription to Premium</td>
              <td className="tabular-nums">$9.99</td>
            </tr>
          </tbody>
          <tfoot className="border-t">
            <tr>
              <td className="pr-8">Total</td>
              <td className="tabular-nums">$9.99</td>
            </tr>
          </tfoot>
        </table>
        <div className="flex space-x-4 items-center">
          <button onClick={onUpgradeClicked} className="btn btn-primary">
            Check out
          </button>
          <Link to="/plans" className="btn btn-link">
            Cancel
          </Link>
        </div>
        <p className="text-sm">
          We partner with <strong>Stripe</strong> for simplified billing.
        </p>
      </div>
    </div>
  )
}
