import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAccount, useApi } from '../hooks'
import { Book } from '../types'

export const BooksPage = () => {
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [books, setBooks] = useState<Book[]>([])
  const [creating, setCreating] = useState(false)
  const history = useHistory()
  const [error, setError] = useState(false)
  const { account } = useAccount()

  const refreshBooks = async () => {
    try {
      setLoading(true)
      const books = await api!.getBooks()
      setBooks(books)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    if (api) refreshBooks()
  }, [api])

  const onCreateClicked = useCallback(async () => {
    setCreating(true)
    const book = await api?.createBook()
    setCreating(false)
    if (book) history.push(`/books/${book._id}`)
  }, [api])

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex justify-between items-end">
        <h2>My book covers</h2>
      </div>
      {error && <p className="text-red-600">An error occurred.</p>}
      {loading ? (
        <p>Loading…</p>
      ) : (
        <>
          {books.length === 0 ? (
            <p>You don’t have any book cover yet. Create your first one now!</p>
          ) : (
            <ul className="grid grid-cols-1 gap-2 sm:gap-4 lg:gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {books.map((book) => (
                <li key={book._id} className="flex flex-col">
                  <Link
                    to={`/books/${book._id}`}
                    className="card flex items-center space-x-4"
                  >
                    <div className="w-8 h-8 border rounded overflow-hidden">
                      {book.coverUrl && (
                        <img
                          src={book.coverUrl}
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                    <span>{book.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className="flex flex-col space-y-2">
            <button
              disabled={
                creating || (books.length > 0 && !account?.subscription)
              }
              className="btn btn-primary self-start"
              onClick={onCreateClicked}
            >
              {creating ? 'Creating the book…' : 'Create a new book'}
            </button>
            {books.length > 0 && account && !account.subscription ? (
              <div className="flex flex-col space-y-2 items-start sm:flex-row sm:space-x-2 sm:space-y-0 sm:items-center">
                <Link
                  to="/plans"
                  className="text-xs bg-yellow-700 text-white px-2 py-1 rounded-full hover:bg-yellow-600"
                >
                  ★ Premium feature
                </Link>
                <span className="italic text-sm">
                  Free accounts come with one book cover. Upgrade to create
                  more!
                </span>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  )
}
