import React from 'react'

export interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string
  unit: string
}

export const RangeInput = ({ label, unit, ...inputProps }: Props) => {
  return (
    <label className="flex flex-col">
      <span className="text-sm">{label}</span>
      <span className="flex space-x-1 items-center">
        <input type="range" className="flex-auto" {...inputProps} />
        <input type="number" className="w-20 flex-none" {...inputProps} />
        <span className="text-sm w-10 flex-none">{unit}</span>
      </span>
    </label>
  )
}
